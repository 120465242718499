.dark-mode {
  color: $default-color !important;

  small {
    color: $default-color;
  }

  .example {
    color: $default-color;
  }

  .table h5,
  .rt-table tbody,
  .table tbody,
  .table td {
  color: $white-color-darkmode;
}

.table th {
  color: $default-color;
}

  .rt-table,
  .rt-table tr,
  .rt-table td,
  .rt-table th,
  tr,td,th {
    border-color: $default-color !important;
  }


  .rt-table tbody tr:hover td,
  .table-hover tbody tr:hover th,
  .table-hover tbody tr:hover td {
    /* background-color: $custom-purple-bg; */
    color: $white-color-darkmode;
  }

  /*  ---- Cards ---- */
  @include modal-colors($card-black-color, $white-color-darkmode);

  @include input-coloured-bg-dark(
    $default-color,
    $default-color,
    $primary-color,
    $card-black-color,
    $warning-color,
    $primary-color,
    $card-black-color,
    $default-color,
    $white-color-darkmode
  );

  .main-panel {
    background-color : $dark-mode-background;
  }

  .card {
    background-color: $card-black-color;
    color: $white-color-darkmode;
    box-shadow: 0px -2px 40px rgba(0, 0, 0, 0.904) !important;
    
    p {
      color: $default-color;
    }
  }

  label {
    color:$default-color;
  }

  /* .card:hover, */
  .chart-card:hover,
  .card-stats:hover {
    background-color: $card-black-color-hover;
  } 

  .modal-content {
    background-color: $card-black-color-hover !important;
    box-shadow: 0px -2px 30px rgb(0, 0, 0) !important;
  }


  /* .nothing {
    filter: invert(0.85);
  } */


  .list-group-item {
    background-color: $card-black-color;
    border-color: $default-color;
    color: $white-color-darkmode;
  }

  /* .table-hover > tbody > tr:hover {
    background-color: $default-color !important;
  } */

  .timeline {
    &:before {
      background-color: darken($default-color, 3%);
    }
    h6 {
      color: $primary-color;
    }

    > li > .timeline-panel {
      background-color: $white-color-darkmode;
      color: $black-color;
      &:before {
        border-left: 15px solid $black-color;
        border-right: 0 solid $black-color;
      }

      &:after {
        border-left: 14px solid $black-color;
        border-right: 0 solid $black-color;
      }
    }

    > li.timeline-inverted > .timeline-panel {
      background-color: transparent;
      color: $white-color-darkmode;
    }
  }

  .sidebar{
    .sidebar-wrapper{
      li.active{
        > a:not([data-toggle="collapse"]),
        > [data-toggle="collapse"] + div .nav li {
          &:before{
            border-right: 17px solid $dark-mode-background;
          }
          &:after{
            border-right: 17px solid $dark-mode-background;
          }
        }
      }
    }
  }
  
  .nav-tabs {
    ul{
      border-bottom: $default-color !important;
    }
    .nav-item{
        .nav-link.active,
        .nav-link.active:hover,
        .nav-link.active:focus{
            background-color: transparent;
            border: 0 none;
            //height: 50px;
            &:after{
                border-bottom: 11px solid $card-black-color;          
            }
            &:before{
                border-bottom: 11px solid $default-color;
            }
        }
    }  
  }

  .nav-tabs-navigation {
    border-bottom: solid 1px $default-color;
  }

  
  .card .css-1uccc91-singleValue,
  .card .react-select input {
    color: $white-color !important;
  }

  .form-control {
    color:$white-color-darkmode !important;
  }

  [type="date"]::-webkit-calendar-picker-indicator {
    filter : invert(1);
  }

  .modal-body select:disabled,
  .modal-body input:disabled {
    background-color: $default-color !important;
    color: $white-color-darkmode !important;
  }

  .modal-header {
    color: $white-color-darkmode;
    border-bottom: solid 1px $default-color !important;
  }

  .dropdown-menu-right {
    background-color: lighten($card-black-color, 8%);
    color : $white-color-darkmode;
  
    .dropdown-item:hover{
      background-color: darken($info-color, 15%);
    }
  }
  
  .dropdown-menu-right:before,
  .dropdown-menu-right::after {
    border-bottom: solid 11px lighten($card-black-color, 5%) !important;
  }
}


