.notificationCard{
  margin-top:"0px";
  margin-bottom:"0px";
  margin-left:"0px";
  margin-right:"0px";
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.notificationCard.btn{
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.content{
  /*margin-top: 179px !important;*/
}
/*
nav{
  margin-bottom: 255px !important;
}
*/