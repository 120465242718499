body {
  color: $black-color;
  font-size: $font-size-base;
  font-family: $sans-serif-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  background: $white-color;
}
/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
  @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
  @include rotate-180();
}

.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: $white-bg;

  & + .button-bar {
    margin-top: 7px;
  }

  &:nth-child(2) {
    width: 17px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.offline-doc {
  .navbar.navbar-transparent {
    padding-top: 25px;
    border-bottom: none;

    .navbar-minimize {
      display: none;
    }
    .navbar-brand,
    .collapse .navbar-nav .nav-link {
      color: $white-color !important;
    }
  }
  .footer {
    z-index: 3 !important;
  }
  .page-header {
    .container {
      z-index: 3;
    }
    &:after {
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}

.fixed-plugin {
  .dropdown-menu li {
    padding: 2px !important;
  }
}

// badge color

.badge {
  &.badge-default {
    @include badge-color($default-color);
  }
  &.badge-primary {
    @include badge-color($primary-color);
  }
  &.badge-info {
    @include badge-color($info-color);
  }
  &.badge-success {
    @include badge-color($success-color);
  }
  &.badge-warning {
    @include badge-color($warning-color);
  }
  &.badge-danger {
    @include badge-color($danger-color);
  }
  &.badge-neutral {
    @include badge-color($white-color);
    color: inherit;
  }
}

.card-user {
  form {
    .form-group {
      margin-bottom: 20px;
    }
  }
}

.console-type {
  background: $black-color;
  color: $white-color;
  width: 100%;
  height: 200px;
  display: block;
  max-width: 100%;
  line-height: 1.5;
  padding: 15px 15px 30px;
  border-radius: 3px;
  border: 1px solid #f7e98d;
  transition: box-shadow 0.5s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-menu {
  .badge {
    transition: all 0.34s;
    -webkit-transition: all 0.34s;
    -moz-transition: all 0.34s;
  }

  .fa-circle-thin {
    color: #ffffff;
  }

  .active .fa-circle-thin {
    color: #00bbff;
  }


  // -- HERE WE ARE SETTING COLOR BADGE'S PROPERTIES --
  .badge {
    border: 3px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;

    // Custom badge colors
    &.badge-default,
    &.badge-black,
    &.badge-brown,
    &.badge-purple,
    &.badge-navy,
    &.badge-aqua,
    &.badge-green,
    &.badge-orange,
    &.badge-red,
    &.badge-pink,
    &.badge-gold,
    &.badge-tcs,
    &.badge-rbw,
    &.badge-gr1,
    &.badge-gr2,
    &.badge-gr3,
    &.badge-gr4,
    &.badge-gr5,
    &.badge-gr6,
    &.badge-gr7,
    &.badge-gr8,
    &.badge-gr9,
    &.badge-sky {
      border: 3px solid $light-gray;

      &.active,
      &:hover {
        border: 3px solid #0bf !important;
      }
    }

    &.badge-white {
      border: 3px solid rgb(228, 228, 228)  !important;

      &.active,
      &:hover {
        border: 3px solid #0bf !important;
      }
    }
  }

  .badge.active,
  .badge:hover {
    border-color: #00bbff;
  }

  .badge-blue {
    background-color: $brand-info;
  }
  .badge-green {
    background-color: $brand-success;
  }
  .badge-orange {
    background-color: $brand-primary;
  }
  .badge-yellow {
    background-color: $brand-warning;
  }
  .badge-red {
    background-color: $brand-danger;
  }
  .badge-dark {
    background-color: $brand-inverse;
  }
}
