/* Some custom CSS to create custom styles out of the original template.  */

.card,
.card-stats {
    width: 100%;
    height: 98%;
    word-wrap: normal !important;
}

.large-chart-card{
    padding : 10px;
}

.chart-card:hover,
.card-stats:hover {
    transform: scale(1.3); 
    z-index: 1;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3);
}

.card-category {
    font-size: .95rem !important;
}

/* fix Hover making zoomed cards get out of sight */
.col-lg-3:first-of-type .card:hover,
.col-lg-2:first-of-type .card:hover {
    z-index: 1;
    transform: scale(1.3) translateX(20px) !important;
}

.col-lg-3:last-of-type .card:hover,
.col-lg-2:last-of-type .card:hover {
    z-index: 1;
    transform: scale(1.3) translateX(-25px) !important;
}
/* End hover */

.d-flex {
    margin-bottom: 20px;
}


.my-card-title {
    text-align: left !important;
    font-size: 1.1rem;
}

.icon-bigger {
    font-size: 2rem !important;
}

.icon-md {
    font-size: 2.1rem !important;
}

.loading {
    margin: 10 auto;
    font-size: 2rem;
}


/* Little pill boxes on top of navbar*/
.little-box {
    width: 100px !important;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
}

.small {
    display: inline-block;
    height: 100%;
}

.pill {
    width: 100% !important;
    max-width: 60px !important;
}

.stick-to-bottom {
    position: absolute !important;
    bottom: 10px !important;
    width: 100%;
    overflow-x: hidden; 
}

.card-bottom {
    position: absolute !important;
    bottom: 0px !important;
}

hr {
    border : 1 !important;
   background-color: #9A9A9A !important;
}

.full-page-background {
    background-size : auto !important;
    background-repeat : no-repeat;
    background-position: center;
    height : 100%;
}

/* --- CCP --- */
.fixed-plugin .dropdown-menu {
    padding: 10px;
    width: 350px;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.4);
}

.dark-mode .fixed-plugin .dropdown-menu,
.dark-mode .ccp-header {
    background-color: #383846;
    box-shadow: 20px 20px 30px 20px rgba(0, 0, 0, 0.74);
}


/* Hide Authenticator */
.Form__formContainer___1GA3x {
    display : none;
}


.right {
    text-align: right !important;
}

body {
    --danger-color : #FB404B;
    --default-color : #626675;
}

/* Manager View and tables in general */

.table-responsive {
    max-height: 550px !important;
    scroll-behavior: smooth !important;
}

.table-sm {
    height: 15rem;
    overflow-x: hidden;
}

.rt-table tr,
.table-hover tr {
    transition: all .0s;
}

.rt-table tbody tr:hover,
.table-hover tbody tr:hover {
    background-color: #034896;
    color: white;
}  

.rt-table td{
    word-wrap: break-word;
    padding: 5px !important;
}

.modal thead th:first-of-type {
    border-top : none;
}



.tickets-table th,
.tickets-table td {
    text-align: center;
    border : solid 1px rgba(128, 128, 129, 0.199) !important;
}

/* React Table Cells*/
.rt-th,
.rt-td { 
    width: 160px !important;
}


/* --- Scroll bar --- */
/* width */

*::-webkit-scrollbar {
    width:13px !important;
    height: 13px !important;
}


/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 8px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgb(173, 173, 173);
}


/* This part will fix react-table to look better */ 

.rt-table {
    display: table !important;
    width: 100% !important;
    max-width: 800px !important;
    table-layout: fixed !important;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
}

.responsive-scroll {
    min-height: 600px !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
}

.rt-table td {
    padding: 4px;
}

.rt-resizable-header-content{
    text-align: center;
}

/* Custom purple button */
.btn-purple { 
    background-color : #9772e0;
}

.btn-purple:hover,
.btn-purple:focus { 
    background-color : #785ab4 !important;
}


.text-purple{
    color : #9b75e7 !important;
}

.text-salmon {
    color : #ec926e !important;
}


.col-lg-2,
.col-md-2 {
    padding: 7px;
}


/* KB Articles inner html */
.tdwrap a {
    color : #51bcda  !important;
}



.-btn {
    background-color:#51cbce !important;
    display: flex;
    height: 39px !important;
    width: 80% !important;
    position: absolute;
    top: 0;
    color: white !important;
}

.-btn:hover {
    background-color:#49b9bb !important;
}

.css-1uccc91-singleValue {
    color: var(--default-color) !important;
  }


/* Worknotes and attachments */
.timeline {
    width: 300px;
}

.timeline-inverted{
    margin-bottom: 20px;
}

.timeline-simple{
    margin:0;
    padding:0;
}

.timeline-body p {
    text-align: justify;
    margin-right: 18px;
}

.nav .active p {
    text-shadow : 0px 0px 10px rgba(255, 255, 255, 0.616) !important;
}


textarea {
    min-height: 150px;
}

#tickets-tab-content {
    min-height: 350px;
    max-height: 680px;
}

.opening-script {
    font-size: 1.1rem;
}

.nav-tabs-navigation {
    border-bottom: solid 1px #b8bdcf;
}

/* This will help breaking lines in notifications dropdown */
.dropdown-item p {
    width: 40rem;
}

.word-break {
    word-break: break-word !important;
    white-space: normal !important;
}

.dropdown-menu-right {
    box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.4) !important;
}

.dark-mode .dropdown-menu-right {
    box-shadow: 20px 20px 30px 20px rgba(0, 0, 0, 0.74) !important;
}

.search-label,
.search-button {
    margin-top: 18px;
}

.search-button-plus {
    margin-top: 26px;
}


.feedback-card {
    padding : 30px;
}

.icon-spin {
    font-size: 1.4rem;
    transition: all .3s;
}

.refresh-link {
    float : right;
    font-size: 1rem;
}

.refresh-link,
.fake-nav-link {
    cursor: pointer;
    transition: all .25s;
}

.refresh-link:hover {
    color :#49b9bb;
}

.refresh-link:hover > .icon-spin,
.fake-nav-link:hover > .icon-spin,
.icon-spin:hover {
    transform:  rotate(140deg);
    cursor: pointer;
}

.main-panel,
.modal-content {
    transition : box-shadow 1s !important;
    transition : color 1s !important;
    transition : background-color 1s !important;
} 



a::after, a::before {
    transition: border 1s !important;
}

/* Fixes MainNavBar mini and full moving everything around */
.cancel-absolute {
    position: relative !important;
}

.content-full .content {
    margin-top: 20px !important;
}

.navbar {
    padding-right: 0px !important;
}

.react-select-sm {
    width: 14.5rem !important;
}

/* SnowActions Panel adjustments */
.snow-btn {
    width: 90%;
}

.opening-script-row {
    margin-bottom: 2%;
}

.opening-script-row,
.snow-actions-row {
    height: 48.6%;
}

.service-1st-row {
    margin-bottom: 15px;
}

.fixed-plugin {
    background-color: #377fc2a9;
}


.kb-body {
    max-height: 450px;
    overflow-y: scroll;
}

